<template>
    <main class="main" v-bar>
        <div>
            <div class="section_top project_top" :class="{ 'project_top_lbg': project.bg.light }" v-if="project && currentLanguage">
                <video v-if="project.bg.type === 'video'" autoplay muted loop playsinline preload="yes" class="bg_video" :poster="project.bg.preview">
                    <source :src="project.bg.src" type="video/mp4">
                </video>
                <img v-if="project.bg.type === 'image'" :src="project.bg.src" class="bg_video" alt="">

                <div class="project_top_content">
                    <img class="project_top_logo" :src="project.logo" alt="project_logo">
                    <div class="project_top_title">
                        <h1 class="project_top_maintitle">{{ project[currentLanguage].title }}</h1>
                        <h2 class="project_top_subtitle">{{ project[currentLanguage].subtitle }}</h2>
                    </div>
                    <div class="social">
                        <a :href="project.social.fb" target="_blank" class="hint--top" :aria-label="$t('project.social.fb')" v-if="project.social.fb">
                            <i class="mdi mdi-facebook"></i>
                        </a>
                        <a :href="project.social.instagram" class="hint--top" target="_blank" :aria-label="$t('project.social.insta')" v-if="project.social.instagram">
                            <i class="mdi mdi-instagram"></i>
                        </a>
                        <a :href="project.social.youtube" class="hint--top" target="_blank" :aria-label="$t('project.social.youtube')" v-if="project.social.youtube">
                            <i class="mdi mdi-youtube"></i>
                        </a>
                        <a :href="project.social.link" class="hint--top" target="_blank" :aria-label="$t('project.social.link')" v-if="project.social.link">
                            <i class="mdi mdi-link"></i>
                        </a>
                        <a :href="project.social.presentation" class="hint--top" target="_blank" :aria-label="$t('project.social.press')" v-if="project.social.presentation">
                            <i class="mdi mdi-presentation"></i>
                        </a>
                        <a :href="project.social.radio" class="hint--top" target="_blank" :aria-label="$t('project.social.radio')" v-if="project.social.radio">
                            <i class="icon-radio"></i>
                        </a>
                    </div>
                </div>
                <div class="scroll_icon">
                    <i class="mdi mdi-chevron-down"></i>
                    <i class="mdi mdi-chevron-down"></i>
                </div>
            </div>
            <div class="section_project container" v-if="project && currentLanguage">
                <h2>{{ $t('project.info') }}</h2>
                <div class="section_project_content">
                    <carousel class="section_project_image project_slider" v-if="project.media.length"
                        :dots="false"
                        :nav="true"
                        :loop="false"
                        :mouseDrag="false"
                        :touchDrag="true"
                        :rewind="true"
                        :items="1"
                        >
                        <a class="project_slider_item" v-for="(child, i) in project.media" :key="i" :href="child.url" :data-fancybox="'group'">
                            <img class="section_project_image" :src="child.preview" alt="Preview">
                        </a>
                    </carousel>

                    <div class="section_project_text">
                        <h3>{{ project[currentLanguage].subtitle }}</h3>
                        <span v-html="project[currentLanguage].text"></span>
                    </div>
                </div>
            </div>
            <div class="section_share container" v-if="project && currentLanguage">
                <div class="share_title">{{ $t('project.share') }}:</div>
                <social-sharing
                    :url="currentLink"
                    :title="project[currentLanguage].title"
                    :description="project[currentLanguage].text | truncate(100)"
                    :quote="project[currentLanguage].subtitle"
                    :hashtags="`bagraev, ${id}, project`"
                    twitter-user="bagraev"
                    inline-template
                >
                    <div class="share_icons">
                        <network class="share_icon share_facebook" network="facebook"><i class="mdi mdi-facebook"></i></network>
                        <network class="share_icon share_telegram" network="telegram"><i class="mdi mdi-telegram"></i></network>
                        <network class="share_icon share_twitter" network="twitter"><i class="mdi mdi-twitter"></i></network>
                        <network class="share_icon share_skype" network="skype"><i class="mdi mdi-skype"></i></network>
                        <network class="share_icon share_email" network="email"><i class="mdi mdi-email-outline"></i></network>
                        <network class="share_icon share_linkedin" network="linkedin"><i class="mdi mdi-linkedin"></i></network>
                    </div>
                </social-sharing>
            </div>
        </div>
    </main>
</template>

<script>
import { TimelineMax } from 'gsap';
import { mapGetters, mapActions } from 'vuex';
import { Trans } from '@/shared/utils/TranslateMiddleware';
import carousel from 'vue-owl-carousel' // eslint-disable-line
import truncate from '@/shared/filters/truncate'; // eslint-disable-line

export default {
    metaInfo() {
        return {
            title: `${this.$t('header.title')} - ${this.$t('menu.projects')}`,
        }
    },
    name: 'ProjectPageComponent',
    components: {
        carousel
    },
    props: {
        id: String
    },
    computed: {
        ...mapGetters('projects', [
            'getList',
            'getProject'
        ]),
        project() {
            return this.getProject(this.id);
        },
        currentLanguage () {
            return Trans.currentLanguage;
        },
        currentLink() {
            return window.location.href;
        }
    },
    watch: {
        project() {
            this.animatePage();

            setTimeout(() => {
                $('.video_js').fancybox({ // eslint-disable-line
                    openEffect: 'fade',
                    closeEffect: 'fade',
                    helpers: {
                        media : {}
                    },
                    thumbs: {
                        autoStart: true,
                        axis: 'x'
                    }
                });

                $('.project_slider_item').fancybox({  // eslint-disable-line
                    openEffect: 'fade',
                    closeEffect: 'fade',
                    thumbs: false
                });
            });
        }
    },
    methods: {
        ...mapActions('projects', [
            'fetchList'
        ]),
        regExTestYT(str){
            const exp = new RegExp(/(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/ ]{11})/i).exec(str); // eslint-disable-line
            if (exp) {
                return `http://img.youtube.com/vi/${exp[1]}/0.jpg`;
            }
        },
        animatePage() {
            var tl = new TimelineMax();
            tl
                .to(('.section_top'), 0.3, {opacity: 1}, '+=0.5')
                .to(('.project_top_logo'), 0.3, {opacity: 1, y: 0})
                .to(('.project_top_title'), 0.3, {opacity: 1})
                .staggerTo(('.project_top_content .social a'), 0.3, {opacity: 1, x: 0}, 0.15)
                .to(('.section_project'), 0.3, {opacity: 1, y: 0})
        },
    },
    mounted() {
        if (!this.getList.length) {
            this.fetchList();
        } else {
            this.animatePage();
        }
        setTimeout(() => {
            $('.video_js').fancybox({ // eslint-disable-line
                openEffect: 'fade',
                closeEffect: 'fade',
                helpers: {
                    media : {}
                },
                thumbs: {
                    autoStart: true,
                    axis: 'x'
                }
            });

            $('.project_slider_item').fancybox({  // eslint-disable-line
                openEffect: 'fade',
                closeEffect: 'fade',
                thumbs: {
                    autoStart: true,
                    axis: 'x'
                }
            });
        });
    }
}
</script>

<style lang="scss" scoped>
</style>